import './App.css';
import ChatPage from './pages/ChatPage';

function App() {
  return (
    <ChatPage/>
  );
}

export default App;
